import React from "react"
import { PageRendererProps } from "gatsby"
import styled from "styled-components"
import SEO from "@/components/SEO"

const title = 'プライバシーポリシー'
const description = '株式会社ゼンアーキテクツのプライバシーポリシーです。'

type PrivacyPolicyPageProps = PageRendererProps

const PrivacyPolicy: React.FC<PrivacyPolicyPageProps> = ({ location }) => (
  <>
    <SEO title={title} description={description} pathname={location.pathname} />
    <Root>
      <Title>Privacy Policy</Title>
      <Content>
        <h2>個人情報の取扱いについて</h2>
        <CorporateName>株式会社ゼンアーキテクツ</CorporateName>
        <p>
          株式会社ゼンアーキテクツ（以下、「当社」と記す。）は、個人情報の重要性を認識し、個人情報保護の活動を当社の基盤的活動として位置づけております。
          当社は、個人情報保護の取組みを真摯に実行することは社会的責務であると認識し、以下の通り個人情報保護方針を定め、役員、社員、協働者に周知し、徹底を図ります。
        </p>
        <ul>
          <li>
            <h3>個人情報の適切な取得、利用、提供、委託</h3>
            1）お客様の個人情報の取得にあたっては、本人又は第三者の権利利益を害するおそれがある場合などを除き、本人に対して利用目的を明らかにし、同意を頂いた上で取得します。取得した個人情報はその目的以外に利用せず、利用範囲を限定し、適切に取り扱います。<br />
            2）取得した個人情報は、法令に基づく命令などを除き、あらかじめお客様の同意を得ることなく第三者に提供することはありません。<br />
            3）取得した個人情報の取扱いを、第三者に委託する場合には十分な個人情報保護の水準を備える者を選び、また、契約等によって保護水準を守るよう定めた上で、指導・管理を実施し、適切に取り扱います。
          </li>
          <li>
            <h3>開示、訂正、利用停止等の求めに応じる手続</h3>
            当社が保有する個人情報については、合理的な範囲で速やかに対応いたします。
          </li>
          <li>
            <h3>個人情報の滅失、き損、漏えいおよび不正アクセスなどの予防ならびに是正</h3>
            当社は、お客様の個人情報を厳格に管理し、滅失、き損、漏えいや不正アクセスなどのあらゆる危険性に対して予防策を実施します。適切な個人情報の取扱いと運用に関する具体的ルールを定め、責任者を設けます。
          </li>
          <li>
            <h3>個人情報に関する法令およびその他の規範の遵守</h3>
            当社の役員、社員、協働者は、個人情報保護や通信の秘密に関する法令やガイドラインその他の関連規範を遵守します。
          </li>
          <li>
            <h3>個人情報保護方針および社内規程類の継続的改善</h3>
            当社は、社会が要請している個人情報保護が効果的に実施されるよう、個人情報保護方針および社内規程類を継続して改善します。
          </li>
          <li>
            <h3>個人情報の取扱いに関する問い合わせおよび相談窓口</h3>
            当社所定の窓口にて、合理的な範囲で対応いたします。
          </li>
        </ul>
        <p>
          代表取締役<br />
          三宅 和之
        </p>
        <p>
          プライバシーポリシーに関するお問い合わせ<br />
          〒106-0032 東京都港区六本木4-5-8<br />
          株式会社ゼンアーキテクツ 経営企画室<br />
          info@zenarc.jp
        </p>
      </Content>
    </Root>
  </>
)

const Root = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.maxSmDown} {
    padding: 80px 0 60px;
  }
  @media ${(props) => props.theme.minMdUp} {
    padding: 130px 15px 100px;
  }
`

const Title = styled.h1`
  font-family: 'Teko', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 60px;
    margin-bottom: 30px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 40px;
    margin-bottom: 20px;
  }
`

const Content = styled.div`
  background-color: #fff;
  margin: 0 auto;
  line-height: 1.75;

  @media ${(props) => props.theme.minMdUp} {
    padding: 40px;
    max-width: 720px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 30px 20px;
    border-radius: 2px;
  }

  h2 {
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 20px;
  }

  p + p {
    margin-top: 20px;
  }

  ul {
    list-style: decimal;
    margin: 30px 0;
    margin-inline-start: 20px;
    padding-inline-start: 0;
  }

  li + li {
    margin-top: 20px;
  }

  h3 {
    font-weight: normal;
    margin-bottom: 5px;
  }
`

const CorporateName = styled.div`
  text-align: right;
  margin-bottom: 30px;
`

export default PrivacyPolicy
